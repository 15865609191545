

import { Component, Vue, Mixins } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { loanInfo, borrowerData } from "../../../models/borrowerData.model";
import striptags from "striptags";
import { HollowDotsSpinner } from "epic-spinners";
import stores from "@/store/store";
import {
  BorrowerInformation,
  BorrowerDetails
} from "@/models/pos/borrower-info.model";
import { LoanAndProperty } from "@/models/pos/loan-and-property.model";
import {
  DemographicInformation,
  DemographicDetails
} from "@/models/pos/demographic-info.model";
import { Acknowledgement, AckDetail } from "@/models/pos/acknowledgement.model";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import {
  Declaration,
  DeclarationDetails
} from "@/models/pos/declaration.model";
import { TransactionDetail } from "@/models/pos/transaction-detail.model";
const store: any = stores;
import saveAs from 'save-as';

@Component({ components: { HollowDotsSpinner } })
export default class ScenarioDeskComponent extends Vue {
  public borrower = new borrowerData();
  public borrowerInfo = new BorrowerInformation();
  public loanAndProperty = new LoanAndProperty();
  public demographicInfo = new DemographicInformation();
  public acknowledgement = new Acknowledgement();
  public financialInfo = new FinancialInfo();
  public declaration = new Declaration();
  public transactionDetail = new TransactionDetail();
  public borrowers = [];
  public toggleData = [];
  public lenderId = "";
  public delete = "yes";
  public idOfMessage = "";
  public selectedBorrowerForDetails: any = null;
  public dotLoader: any = false;
  public loader: Boolean = true;
  public currentMessage = null;
  public lastResponseIndex: any = [];
  public path = "";
  public fileType = "";
  public showAttachmentButton = false;
  public fileIndex = 0;
  public fileArray = [];
  public showNext: Boolean = null;
  public showPrev: Boolean = null;
  public lenderName = [];
  public brokerId = "";
  public lenderNames = [];
  public conversations = [];
  public conversationData = [];
  public sDeskId = "";
  public scenarioDeskData: any = null;
  public email = "";
  public phone = "";
  public emailExists = "";
  public toggleLoader = false;
  public inProcess = false;

  haveToggleOpen(index) {
    return this.toggleData[index];
  }

  public get scenarioDeskCount() {
    return this.$store.state.scenarioDeskCount;
  }

  public set scenarioDeskCount(data) {
    this.$store.state.scenarioDeskCount = data;
  }

  public async getBorrowerListforScenarioDeskMessageList() {
    try {
      this.loader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/getBorrowerListforScenarioDeskMessageList", //to get the list of borrowers with their loan information
        {
          userType: this.$userType,
          userId: this.$userId
        });
      this.borrowers = response.data.borrowers;
      this.conversations = response.data.conversation;
      for (let i = response.data.conversation.length - 1; i >= 0; i--) {
        this.toggleData.push(false);
      }
      this.loader = false;
    } catch (error) {
      this.loader = false;
      console.log(error);
    }
  }
  public async saveLead(selectedBorrower) {
    try {
      this.$modal.show("getEmail");
      if (selectedBorrower) {
        this.scenarioDeskData = selectedBorrower;
      }

      if (!selectedBorrower) {
        this.borrower = new borrowerData();
        this.inProcess = true;
        let formValid = await this.$validator.validateAll();
        if (!formValid) {
          this.inProcess = false;
          return;
        }

        let scenarioDeskObj = {
          statusId: 1,
          edit: false,
          moveToLead: true,
          scenerioDeskId: this.scenarioDeskData._id
        };

        let body = {
          scenarioDeskObj: scenarioDeskObj,
          borrowerData: this.borrower,
          borrowerInfo: this.borrowerInfo,
          loanAndProperty: this.loanAndProperty,
          demographicInfo: this.demographicInfo,
          acknowledgement: this.acknowledgement,
          financialInfo: this.financialInfo,
          declaration: this.declaration,
          transactionDetail: this.transactionDetail,
          loanTxnId: this.scenarioDeskData.loanTxnId
        };

        body.borrowerData.loanTxnId = body.loanAndProperty.loanTxnId = body.borrowerInfo.loanTxnId = body.demographicInfo.loanTxnId = body.acknowledgement.loanTxnId = body.financialInfo.loanTxnId = body.declaration.loanTxnId = body.transactionDetail.loanTxnId = this.scenarioDeskData.loanTxnId;
        this.borrower.addedBy = this.$userId;
        this.borrower.brokerId = this.scenarioDeskData.brokerId;
        this.borrower.originatorModelName = this.$userType;
        this.borrower.source = "scenerioDesk";
        this.borrower.loanInformation = this.scenarioDeskData.loanDetails;
        this.borrower.basicInformation.borrower.address = this.scenarioDeskData.address;
        this.borrower.loanInformation.state = this.scenarioDeskData.address.state;
        this.borrower.loanInformation.zipCode = this.scenarioDeskData.address.zip;
        this.borrower.loanInformation.county = this.scenarioDeskData.address.county;
        this.borrower.loanInformation.minDownPaymentType = "$";
        this.borrowerInfo.borrowerInfo[0].personalInfo.name.firstName = this.scenarioDeskData.loanDetails.firstName;
        this.borrowerInfo.borrowerInfo[0].personalInfo.name.lastName = this.scenarioDeskData.loanDetails.lastName;
        this.borrowerInfo.borrowerInfo[0].personalInfo.contactInformation.email = this.email;
        this.borrowerInfo.borrowerInfo[0].personalInfo.contactInformation.cellPhone = this.phone;

        let response = await Axios.post(
          BASE_API_URL + "loan/submitBorrowerInfo",
          body
        );

        if (response.data.type == "error") {
          if (response.data.emailExist) {
            this.emailExists = response.data.info;
            this.inProcess = false;
          }
        } else {
          this.$snotify.success("Saved successfully");
          await this.getBorrowerListforScenarioDeskMessageList();
          this.inProcess = false;
          this.$modal.hide("getEmail");
          this.phone = "";
          this.email = "";
          this.emailExists = "";
          this.scenarioDeskData = null;
        }
      }
    } catch (error) {
      console.log(error);
      this.inProcess = false;
    }
  }
  hideEmailModal() {
    this.phone = "";
    this.email = "";
    this.emailExists = "";
    this.$modal.hide("getEmail");
  }
  public async toggleDetails(i) {
    let hasResponse = await this.borrowerHasResponse(this.borrowers[i]._id);
    for (let j = 0; j < this.toggleData.length; j++) {
      if (j != i) {
        this.toggleData[j] = false;
      }
    }
    this.toggleData[i] = !this.toggleData[i];
    this.getConversationData(this.borrowers[i]._id);
    this.toggleData = JSON.parse(JSON.stringify(this.toggleData));
  }

  public async getAllSelectedLendersName(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getLenderName",
        {
          userType: this.$userType,
          brokerId: this.$brokerId,
          userId: this.$userId,
          id: id
        });
      this.lenderName = response.data.lenderName;
      return this.lenderName;
    } catch (error) {
      console.log(error);
    }
  }

  public async view(borrower) {
    this.selectedBorrowerForDetails = borrower;
    if (
      this.selectedBorrowerForDetails.selectedLenders[0].attachment.length > 0
    ) {
      this.showAttachmentButton = true;
    }
    this.$modal.show("viewScenarioDeskDetail");

    this.getAllSelectedLendersName(borrower._id);
  }

  public hideModal() {
    this.$modal.hide("showAttachment");
    this.$modal.show("viewScenarioDeskDetail");
  }
  /**************************************************************************************************
   *                                         TO SHOW THE DISPLAY DOCUMENT                            *
   **************************************************************************************************/
  public displayFile(data) {
    try {
      this.$modal.hide("viewScenarioDeskDetail");
      if (data.length > 0) {
        this.fileArray = [];
        this.dotLoader = true;
        data.forEach(element => {
          this.fileArray.push(element.path);
        });
        if (this.fileArray.length > 1) {
          this.showNext = true;
        }
        this.fileIndex = 0;
        this.path = this.fileArray[this.fileIndex];
        this.fileType = this.path
          .split(".")
          .pop()
          .toLowerCase();
        this.$modal.show("showAttachment");
      }
      this.loadDocument(this.path);
    } catch (error) {
      console.log(error);
    }
  }

  public async deleteMessage(id, lenderId, sDeskId, sDeskIndex) {
    let confirmDelete = false;
    await this.$dialog
      .confirm("Are you sure you want to delete?")
      .then(function(dialog) {
        confirmDelete = true;
      })
      .catch(function() {
        confirmDelete = false;
      });

    if (confirmDelete) {
      let body = {
        id: id,
        index: sDeskIndex,
        sDeskId: sDeskId
      };

      let response = await Axios.post(
        BASE_API_URL + "broker/deleteMessage",
        body);
      this.conversationData = response.data.conversationData;
      this.borrowers.forEach(element => {
        if (element._id == sDeskId) {
          element.selectedLenders.forEach(e => {
            if (e.lenderId == lenderId) {
              e.conversation.forEach(e1 => {
                if (e1._id == id && e1.isSeen == false) {
                  this.$store.state.scenarioDeskCount--;
                }
                e1.isCleared = true;
                e1.isSeen = true;
              });
            }
          });
        }
      });
      this.borrowers = JSON.parse(JSON.stringify(this.borrowers));
      this.getBorrowerListforScenarioDeskMessageList();
    }
  }

  public async viewMail(msgId, lenderId, id, brokerId, sDeskId, sDeskIndex) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/clearNotificationCounterOnView",
        {
          msgId: msgId,
          sDeskId: sDeskId,
          lenderId: lenderId
        });

      this.borrowers.forEach(element => {
        if (element._id == sDeskId) {
          element.selectedLenders.forEach(e => {
            if (e.lenderId == lenderId) {
              e.conversation.forEach(e1 => {
                if (e1._id == msgId && e1.isSeen == false)
                  this.$store.state.scenarioDeskCount--;
              });
            }
          });
        }
      });
      this.$router.push({
        path: this.$getCurrentUserTypePath("lender-conversation"),
        query: { lenderId: lenderId, id: id }
      });
    } catch (error) {
      console.log(error);
    }
  }
  public async toSeeFullMessage(message) {
    this.currentMessage = message;
    this.$modal.show("toSeeFullMessage");
  }

  public getLastReplyIndex(lenders) {
    lenders.forEach((element, index) => {
      let response = null;
      for (let i = 0; i < element.conversation.length; i++) {
        if (
          element.conversation[i].type == "response" &&
          response == null &&
          !element.conversation[i].isCleared
        ) {
          response = i;
          this.lastResponseIndex[index] = i;
        }
      }
    });
  }

  public getMessageWithoutTags(message) {
    message = striptags(message, [], " "); //To strip html into normal text.
    message = message.replace(/\s\s+/g, " "); //To remove multiple white spaces as above function create multiple white spaces in case of <ol> and <ul> tags.
    return message;
  }

  public getLastReply(conversation) {
    //to get the last reply of lender
    let response = null;
    for (let i = 0; i < conversation.length; i++) {
      if (
        conversation[i].type == "response" &&
        !response &&
        !conversation[i].isCleared
      ) {
        let msg = striptags(conversation[i].message, [], " "); //To strip html into normal text.
        msg = msg.replace(/\s\s+/g, " "); //To remove multiple white spaces as above function create multiple white spaces in case of <ol> and <ul> tags.

        if (msg.length > 40) {
          msg = msg.substr(0, 40) + " " + "  ...";
        } else {
          msg = conversation[i].message;
        }
        this.currentMessage = conversation[i].message;

        response = {
          createdOn: conversation[i].createdOn,
          message: msg,
          id: conversation[i]._id
        };
      }
    }
    return response;
  }
  public lenderTotalResponse(convo) {
    let count = 0;
    if (convo.type == "response" && !convo.isCleared && !convo.isSeen) {
      count++;
    }
    return count;
  }

  public borrowerHasResponse(id) {
    try {
      let hasResponse = 0;
      this.conversations.forEach(element => {
        if (element.id == id) {
          element.conversation.forEach(e => {
            if (e.type == "response" && !e.isCleared && !e.isSeen) {
              hasResponse++;
            }
          });
        }
      });

      return hasResponse;
    } catch (error) {
      console.log(error);
    }
  }

  public async getConversationData(id) {
    this.toggleLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getConversationData",
        { id: id });
      this.conversationData = response.data.conversationData;
      this.toggleLoader = false;
    } catch (error) {
      this.toggleLoader = false;
      console.log(error);
    }
  }

  public hide() {
    this.showAttachmentButton = false;
    this.$modal.hide("viewScenarioDeskDetail");
    this.lenderNames = [];
  }
  /**************************************************************************************************
   *                                  FOR SLIDING THE IMAGES                                         *
   **************************************************************************************************/
  public changeImageIndex(check) {
    this.dotLoader = true;
    if (check == "next") {
      this.fileIndex++;
      this.showPrev = true;
      this.path = this.fileArray[this.fileIndex];
      this.fileType = this.path
        .split(".")
        .pop()
        .toLowerCase();
      if (this.fileArray.length == this.fileIndex + 1) {
        this.showNext = false;
      }
    }
    if (check == "previous") {
      this.fileIndex--;
      this.showNext = true;
      this.path = this.fileArray[this.fileIndex];
      this.fileType = this.path
        .split(".")
        .pop()
        .toLowerCase();
      if (this.fileIndex == 0) {
        this.showPrev = false;
      }
    }
    this.loadDocument(this.path);
  }

  /**************************************************************************************************
   *                                    IF DOCUMENT NOT LOADED THEN                                  *
   **************************************************************************************************/
  public ifDocumentNotLoad(time: number) {
    this.dotLoader = true;
    try {
      setTimeout(() => {
        let checkLoader = document.getElementById("checkLoader");
        if (checkLoader != null) {
          let iframe: any = document.getElementById("checkElement");
          if (iframe.localName == "iframe") {
            iframe.src =
              "https://docs.google.com/gview?url=" +
              this.path +
              "&embedded=true";
          } else {
            iframe.src = this.path;
          }
          this.ifDocumentNotLoad(time + 1000);
        }
      }, time);
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }
  public openConfirmDeleteFileModal(id) {
    this.sDeskId = id;
    this.$modal.show("confirmDeleteFileModal");
  }
  public async deleteScenarioCollection() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/deleteScenarioCollection",
        {
          id: this.sDeskId
        });
      this.$snotify.success("Data deleted Sucessfully");
      this.$modal.hide("confirmDeleteFileModal");
      this.getBorrowerListforScenarioDeskMessageList();
    } catch (error) {
      console.log(error);
    }
  }

  private async loadDocument(path){
    try {
      let ext = path.split('.').pop();
      if (!['pdf','jpg','jpeg','png'].includes(ext)) return;
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path });
      let dom = document.getElementById('checkElement');
      response.data = 'data:' + (ext != 'pdf' ? 'image' : 'application') + '/' + ext + ';base64,' + response.data;
      dom['src'] = response.data;
      dom.append();
    } catch (error) {
      console.log(error);
    }
  }

  public async downloadFile(file) {
    file = this.fileArray[this.fileIndex];
    this.$store.state.wemloLoader = true;
    let response = await Axios.post(BASE_API_URL+'common/fetchDocumentOnce', { path: file }, { responseType: 'blob'});
    this.$store.state.wemloLoader = false;
    saveAs(response.data, file.split('/').pop());
  }

  mounted() {
    this.getBorrowerListforScenarioDeskMessageList();
  }
}
